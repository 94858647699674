/* path indicator styles */

.end-segment {
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		height: 9px;
		width: 9px;
		border: solid #c00;
		border-width: 0 0px 2px 2px;
		transform: rotate(45deg) translate3d(-40%, -50%, 0);
	}
}
